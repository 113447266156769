<template>
  <div>
    <place-object
      v-for="place in data"
      :key="place.id"
      :data="place"
      :raw-data="rawData"
      class="q-mb-sm"
      @click="handleClick(place)"
    />
  </div>
</template>

<script>
export default {
  name: 'DynamicPlaceCollection',
  emits: ['click'],
  props: {
    rawData: {
      type: Object,
      default () {
        return {}
      }
    },
    data: {
      type: Array,
      default () {
        return []
      }
    }
  },
  methods: {
    handleClick (place) {
      const data = {
        event: 'storage.place.opened',
        entityClass: 'Orderadmin\\Storage\\Entity\\Place',
        data: place
      }
      this.$emit('click', data)
    }
  }
}
</script>
